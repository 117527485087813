"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TelemetryPdfOrigin = exports.TelemetryPdfType = exports.TelemetrySource = exports.TelemetryContext = exports.TableWidgetInteractions = exports.ButtonWidgetInteractions = exports.ROICalculatorInteractions = exports.SidebarAssetAddedType = exports.SidebarTabType = exports.SidebarAssetAddedInsertMethod = exports.SidebarOpenOrigin = exports.SavedBlockOrigin = exports.UserInvitedOrigin = void 0;
var UserInvitedOrigin;
(function (UserInvitedOrigin) {
    UserInvitedOrigin["TeamSharePanel"] = "team share panel";
    UserInvitedOrigin["TeamSettings"] = "team settings";
    UserInvitedOrigin["Mention"] = "mention";
    UserInvitedOrigin["InviteRequest"] = "invite request";
})(UserInvitedOrigin || (exports.UserInvitedOrigin = UserInvitedOrigin = {}));
var SavedBlockOrigin;
(function (SavedBlockOrigin) {
    SavedBlockOrigin["Dashboard"] = "dashboard";
    SavedBlockOrigin["Editor"] = "editor";
    SavedBlockOrigin["Sidebar"] = "sidebar";
})(SavedBlockOrigin || (exports.SavedBlockOrigin = SavedBlockOrigin = {}));
var SidebarOpenOrigin;
(function (SidebarOpenOrigin) {
    SidebarOpenOrigin["LibraryButton"] = "library button";
    SidebarOpenOrigin["AddBlockMenu"] = "add block menu";
    SidebarOpenOrigin["ImageWidgetAdder"] = "widget adder menu image";
})(SidebarOpenOrigin || (exports.SidebarOpenOrigin = SidebarOpenOrigin = {}));
var SidebarAssetAddedInsertMethod;
(function (SidebarAssetAddedInsertMethod) {
    SidebarAssetAddedInsertMethod["Drag"] = "drag";
    SidebarAssetAddedInsertMethod["Click"] = "click";
})(SidebarAssetAddedInsertMethod || (exports.SidebarAssetAddedInsertMethod = SidebarAssetAddedInsertMethod = {}));
var SidebarTabType;
(function (SidebarTabType) {
    SidebarTabType["Blocks"] = "blocks";
    SidebarTabType["Images"] = "images";
})(SidebarTabType || (exports.SidebarTabType = SidebarTabType = {}));
var SidebarAssetAddedType;
(function (SidebarAssetAddedType) {
    SidebarAssetAddedType["Block"] = "block";
    SidebarAssetAddedType["Image"] = "image";
    SidebarAssetAddedType["VideoLink"] = "video_link";
})(SidebarAssetAddedType || (exports.SidebarAssetAddedType = SidebarAssetAddedType = {}));
var ROICalculatorInteractions;
(function (ROICalculatorInteractions) {
    ROICalculatorInteractions["SliderMoved"] = "ROI Slider Moved";
    ROICalculatorInteractions["SliderOptionsEdited"] = "ROI Slider Options Edited";
    ROICalculatorInteractions["VariableAdded"] = "ROI Input Variable Added";
    ROICalculatorInteractions["VariableDeleted"] = "ROI Input Variable Deleted";
    ROICalculatorInteractions["VariableChanged"] = "ROI Input Variable Value Changed";
    ROICalculatorInteractions["VariableOptionEdited"] = "ROI Input Variable Edited";
    ROICalculatorInteractions["ResultOptionEdited"] = "ROI Options Edited";
    ROICalculatorInteractions["BackgroundAdded"] = "ROI Background Image Added";
    ROICalculatorInteractions["BackgroundRemoved"] = "ROI Background Image Removed";
    ROICalculatorInteractions["TourStarted"] = "ROI Tour Started";
    ROICalculatorInteractions["TourSkipped"] = "ROI Tour Skipped";
    ROICalculatorInteractions["ROIPresetSelected"] = "ROI Preset Selected";
})(ROICalculatorInteractions || (exports.ROICalculatorInteractions = ROICalculatorInteractions = {}));
var ButtonWidgetInteractions;
(function (ButtonWidgetInteractions) {
    ButtonWidgetInteractions["ButtonUrlEdited"] = "Button URL Edited";
    ButtonWidgetInteractions["ButtonTextEdited"] = "Button Text Edited";
    ButtonWidgetInteractions["ButtonUrlCustomTokenUpdated"] = "Button URL Custom Token Updated";
})(ButtonWidgetInteractions || (exports.ButtonWidgetInteractions = ButtonWidgetInteractions = {}));
var TableWidgetInteractions;
(function (TableWidgetInteractions) {
    TableWidgetInteractions["RepeatingRowAdded"] = "Table Repeating Row Added";
    TableWidgetInteractions["RepeatingRowTokenSet"] = "Table Repeating Row Token Set";
})(TableWidgetInteractions || (exports.TableWidgetInteractions = TableWidgetInteractions = {}));
var TelemetryContext;
(function (TelemetryContext) {
    TelemetryContext["Public"] = "page";
    TelemetryContext["Client"] = "editor";
})(TelemetryContext || (exports.TelemetryContext = TelemetryContext = {}));
var TelemetrySource;
(function (TelemetrySource) {
    TelemetrySource["Dashboard"] = "dashboard";
    TelemetrySource["PageExplorer"] = "page explorer";
    TelemetrySource["Editor"] = "editor";
    TelemetrySource["DashboardSearch"] = "dashboard search";
    TelemetrySource["Template"] = "template";
    TelemetrySource["CollaboratorPage"] = "collaborator page";
    TelemetrySource["Integration"] = "integration";
    TelemetrySource["Hubspot"] = "hubspot";
    TelemetrySource["StoryBook"] = "storybook";
    TelemetrySource["CreatePageApp"] = "create page app";
    TelemetrySource["CollaboratorComment"] = "collaborator comment";
    TelemetrySource["PageAnalytics"] = "page analytics";
})(TelemetrySource || (exports.TelemetrySource = TelemetrySource = {}));
var TelemetryPdfType;
(function (TelemetryPdfType) {
    TelemetryPdfType["AuditTrail"] = "audit trail";
    TelemetryPdfType["Page"] = "page";
    TelemetryPdfType["Agreement"] = "agreement";
})(TelemetryPdfType || (exports.TelemetryPdfType = TelemetryPdfType = {}));
var TelemetryPdfOrigin;
(function (TelemetryPdfOrigin) {
    TelemetryPdfOrigin["Editor"] = "editor";
    TelemetryPdfOrigin["Dashboard"] = "dashboard";
    TelemetryPdfOrigin["CollaboratorPage"] = "collaborator";
    TelemetryPdfOrigin["PublicPage"] = "public-page";
    TelemetryPdfOrigin["NotificationEmail"] = "email";
    TelemetryPdfOrigin["Springboard"] = "springboard";
})(TelemetryPdfOrigin || (exports.TelemetryPdfOrigin = TelemetryPdfOrigin = {}));
